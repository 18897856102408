import { useMemo, useState } from 'react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import { Pagination, Arrow } from '@egjs/flicking-plugins';
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
// import 'swiper/swiper-bundle.min.css';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import 'swiper/swiper.min.css';
import '@egjs/react-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/arrow.min.css';
import '@egjs/flicking-plugins/dist/pagination.css';
// Or, if you have to support IE9
import '@egjs/react-flicking/dist/flicking-inline.css';
import { isMobile } from 'react-device-detect';
import { isArray, isString, sortBy } from 'lodash';
import { Button } from 'react-bootstrap';
import { useRouter } from 'next/router';
import { COLOURS } from '../styles/Constants';
import { MiniProfileView } from './ProfileViews';
import styles from './MiniProfileViewCollection.module.css';
import useWindowSize from '../hooks/useWindowSize';

const arrowSize: React.CSSProperties = {
  fontSize: '4em',
};

export const MiniProfileViewCollection = (item: {
  style?: React.CSSProperties;
  title: string;
  topics: any;
}): JSX.Element => {
  const [selected, setSelected] = useState('0');
  const [panels, setPanels] = useState([0, 1, 2, 3, 4]);
  const { topics, style, title } = item;
  const router = useRouter();
  const window = useWindowSize();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
  };

  const memoizedTopics = useMemo(() => sortBy(topics, 'sortOrder'), [topics]);

  const itemGap: number = 20;
  const sideGap: number = isMobile ? 10 : 150;
  const maxItems: number = window.width / (sideGap + 110 + itemGap);
  const plugins = [new Arrow()];
  // const plugins = [new Pagination({ type: 'bullet' })];

  return (
    <div
      style={{
        color: COLOURS.white,
        textAlign: 'left',
        marginLeft: '5%',
        marginRight: '5%',
        overflow: 'hidden',
        // width: '50vw',
        ...style,
      }}
    >
      <Button
        style={{
          marginLeft: 5,
          marginTop: 5,
          paddingRight: 0,
          paddingBottom: 0,
          background: 'transparent',
          border: 'transparent',
        }}
        onClick={() => {
          (async () => {
            if (isArray(topics) && item.topics.length > 0) {
              const { title: channelName } = item;
              // Topic may belong to multiple channels. The local channel store may have topic with a "wrong" channelName because that's the first topic entry to be saved in local store
              const firstTopicOfCollection = topics[0];
              // const firstTopicWithMatchingChannelName = topics.filter(
              //   (e) => e.channelName === channelName && isString(e.channelUuid)
              // )[0];
              // console.log(topics);
              const { nonce, channelUuid } = firstTopicOfCollection;
              // console.log(channelName);
              // console.log(nonce);
              // console.log(channelUuid);
              await router.push(
                `/Search?query=${channelName}&uuid=${channelUuid}&nonce=${nonce}&type=channel`,
                `/Search?query=${channelName}`
              );
            }
          })();
        }}
      >
        <h3>
          {title}
          <IoIosArrowForward />
        </h3>
      </Button>
      <div
        style={{
          // height: 400,
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
        }}
      >
        <Flicking
          align={isMobile ? 'prev' : { camera: '0%', panel: '-1000px' }}
          // circular={true}
          onMoveEnd={(e) => {}}
          plugins={plugins}
          bound
          duration={1000}
          inputType={['touch', 'mouse']}
          moveType="snap"
          preventClickOnDrag
          interruptable
          renderOnlyVisible
        >
          {memoizedTopics.map((e: any, index: number) => (
            <div className="panel" style={{ paddingLeft: 50 }} key={index}>
              <MiniProfileView
                key={e.uuid}
                style={{ display: 'inline-block' }}
                {...e}
              />
            </div>
            // </div
          ))}
          <ViewportSlot>
            <span className="flicking-arrow-prev" style={{ marginLeft: -20 }} />
            <span className="flicking-arrow-next" />
            {/*<span className="flicking-pagination" />*/}
          </ViewportSlot>
        </Flicking>
      </div>
      {/* <ScrollMenu */}
      {/*  selected={selected} */}
      {/*  arrowLeft={<IoIosArrowBack style={arrowSize} />} */}
      {/*  menuClass={styles.scrollMenu} */}
      {/*  wrapperClass={styles.wrapperMenu} */}
      {/*  arrowRight={<IoIosArrowForward style={arrowSize} />} */}
      {/*  scrollBy={2} */}
      {/*  wheel={false} */}
      {/*  data={item.topics.map((prof: any) => ( */}
      {/*    <MiniProfileView */}
      {/*      key={prof.uuid} */}
      {/*      style={{ display: 'inline-block', marginRight: '2em' }} */}
      {/*      {...prof} */}
      {/*    /> */}
      {/*  ))} */}
      {/* /> */}
    </div>
  );
};
